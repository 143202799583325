









import { Vue, Component } from 'vue-property-decorator'
import DeviceContainer from './components/DeviceContainer.vue'

@Component({
  components: {
    DeviceContainer
  }
})
export default class App extends Vue {
}

















import { Vue, Component } from 'vue-property-decorator'

@Component
export default class BackgroundVideoPlayer extends Vue {
  get videoSource () {
    return this.$store.state.background_video
  }
}

import Vue from 'vue'
import Vuex from 'vuex'
import { api } from '@/services/api.service'
import { game } from './modules/gameModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    background_video: null,
    quiz_id: null,
    question: null,
    answer: null,
    score: 0,
    position: 1,
    better: 0,
    correct: false,
    audioSource: '',
    clickout_link: null,
    powered_by: null,
    nextQuestionVideo: null,
    isMuted: false,
    prize: {
      prize_enabled: false
    },
    username: null,
    lastAreaId: null,
    lastZoneId: null
  },
  mutations: {
    setLastAreaId (state, id) {
      state.lastAreaId = id
    },
    setLastZoneId (state, id) {
      state.lastZoneId = id
    },
    setPrize (state, prize) {
      state.prize = prize
    },
    setIsMuted (state, isMuted) {
      state.isMuted = isMuted
    },
    setQuizId (state, quiz_id) {
      state.quiz_id = quiz_id
    },
    setPosition (state, position) {
      state.position = position
    },
    setQuestion (state, payload) {
      state.question = payload
    },
    setAnswer (state, answer) {
      state.answer = answer
    },
    setScore (state, payload) {
      state.score = payload
    },
    setCorrect (state, correct) {
      state.correct = correct
    },
    setBackgroundVideo (state, payload) {
      state.background_video = payload
    },
    setBetter (state, better) {
      state.better = better
    },
    setAudioSource (state, audioSource) {
      state.audioSource = audioSource
    },
    setClickoutLink (state, clickout_link = null) {
      state.clickout_link = clickout_link
    },
    setPoweredBy (state, powered_by) {
      state.powered_by = powered_by
    },
    setNextQuestionVideo (state, nextQuestionVideo) {
      state.nextQuestionVideo = nextQuestionVideo
    },
    setUsername (state, username) {
      state.username = username
    }
  },
  actions: {
    async loadAudio ({ commit }, audioSource: string) {
      if (audioSource !== '') {
        commit('setAudioSource', audioSource)
        return
      }

      const file = await import('@/assets/silence.mp3')

      commit('setAudioSource', file.default)
    },
    async getNextQuestion ({ commit, state }) {
      const { data } = await api.request.get('/play')

      commit('setQuizId', data.quiz_id)
      commit('setScore', data.score)
      commit('setPosition', data.position)
      commit('setPoweredBy', data.powered_by)
      commit('setPrize', data.prize)

      if (data.question) {
        if (state.nextQuestionVideo) {
          commit('setQuestion', {
            ...data.question,
            background_video: state.nextQuestionVideo
          })
          commit('setNextQuestionVideo', null)
        } else {
          commit('setQuestion', data.question)
        }

        if (data.next_question_video) {
          commit('setNextQuestionVideo', data.next_question_video)
        }

        if (data.question.correct_background_video) {
          commit('setQuestion', { ...data.question, correct_background_video: data.question.correct_background_video })
        }

        if (data.question.wrong_background_video) {
          commit('setQuestion', { ...data.question, wrong_background_video: data.question.wrong_background_video })
        }
      } else {
        commit('setBetter', data.better)
        commit('setClickoutLink', data.clickout_link)
        commit('setUsername', data.username)

        throw new Error('show-results')
      }
    },
    async answerQuestion ({ commit, state }, payload = {}) {
      const question = state.question

      if (question === null) {
        throw new Error('question cannot be null')
      }

      const { id: question_id } = question

      const { data } = await api.request.post('/answer', {
        ...payload,
        quiz_id: state.quiz_id,
        question_id
      })

      commit('setAnswer', data.answer)
      commit('setCorrect', data.correct)
      commit('setScore', data.score)

      return data
    },
    async loadQuestion ({ commit }, questionId) {
      const { data } = await api.request.get(`/preview/${questionId}`)

      commit('setQuestion', data)
    }
  },
  modules: {
    game
  }
})

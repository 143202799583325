import axios, { AxiosInstance } from 'axios'
import { Route, NavigationGuardNext } from 'vue-router'
import router from '../router'
import { API_URL } from '../config/app'

class ApiService {
  request: AxiosInstance

  constructor () {
    this.request = axios.create({
      baseURL: API_URL,
      headers: {
        common: {
          Accept: 'application/json'
        }
      }
    })

    this.request.interceptors.response.use((response) => response, (error) => {
      if (error.response.status === 401) {
        // alert('Session Expired')

        window.localStorage.removeItem('token')

        router.push({ name: 'login' })
      } else {
        throw error
      }
    })

    this.setToken()
  }

  setToken () {
    const token = window.localStorage.getItem('token')

    if (token) {
      this.request.defaults.headers.common = {
        Authorization: `Bearer ${token}`
      }
    }
  }
}

export const api = new ApiService()

export const auth = async (to: Route, from: Route, next: NavigationGuardNext) => {
  const { token, slug, redirect } = to.query

  if (!token) {
    next({ name: 'login' })
  }

  try {
    const { data } = await api.request.post('/auth', { token })

    window.localStorage.setItem('token', data.token)
    window.localStorage.setItem('slug', slug as string)

    api.setToken()

    if (redirect) {
      next({ name: redirect as string })
    } else {
      next({ name: 'dashboard' })
    }
  } catch (error) {
    console.log(error)

    alert('invalid token')

    next({ name: 'login' })
  }
}

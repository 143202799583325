import { RouteConfig } from 'vue-router'
import Login from '../views/Login.vue'

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'login' }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/preview',
    name: 'preview',
    component: () => import(/* webpackChunkName: "preview" */ '../views/Preview.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/quiz/question',
    name: 'question',
    component: () => import(/* webpackChunkName: "quiz" */ '../views/Question.vue')
  },
  {
    path: '/quiz/results',
    name: 'results',
    component: () => import(/* webpackChunkName: "quiz" */ '../views/Results.vue')
  },
  {
    path: '/quiz/answer',
    name: 'answer',
    component: () => import(/* webpackChunkName: "quiz" */ '../views/Answer.vue')
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: () => import(/* webpackChunkName: "leaderboard" */ '../views/Leaderboard.vue')
  },
  {
    path: '/prize',
    name: 'prize',
    component: () => import(/* webpackChunkName: "prize" */ '../views/Prize.vue')
  },
  {
    path: '/*',
    name: 'default',
    redirect: { name: 'home' }
  }
]

export default routes

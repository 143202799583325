














import { Vue, Component, PropSync } from 'vue-property-decorator'

@Component
export default class EmailInput extends Vue {
  @PropSync('email', { type: String, default: '' }) syncedEmail!: string
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  const token = window.localStorage.getItem('token')

  if (to.name === 'customgame' || to.name === 'preview') {
    return next()
  }

  if (to.name === 'auth') {
    if (token) {
      window.localStorage.removeItem('token')
    }

    return next()
  }

  if (to.name === 'login') {
    if (token) {
      return next({ name: 'dashboard' })
    }

    return next()
  }

  if (!token) {
    return next({ name: 'login' })
  }

  return next()
})

export default router




























import { Vue, Component } from 'vue-property-decorator'
import BackgroundVideoPlayer from './BackgroundVideoPlayer.vue'

@Component({
  components: {
    BackgroundVideoPlayer
  }
})
export default class DeviceContainer extends Vue {
  goHome () {
    if (this.$route.name === 'preview') {
      window.location.reload()
      return
    }

    const token = window.localStorage.getItem('token')

    if (token) {
      this.$router.replace({ name: 'dashboard' })
    } else {
      this.$router.replace({ name: 'home' })
    }
  }
}









































import { Component, Vue } from 'vue-property-decorator'
import EmailInput from '../components/login/EmailInput.vue'
import { api } from '../services/api.service'
import Swal from 'sweetalert2'

@Component({
  components: {
    EmailInput
  }
})
export default class LoginView extends Vue {
  name = ''
  code = ''
  isLoading = false
  isEmailSent = false

  async attemptLogin () {
    this.isLoading = true

    // validate inputs
    const isCodeValid = this.code.length === 4

    if (!isCodeValid) {
      Swal.fire({
        icon: 'error',
        text: 'You must enter a 4-digit code',
        showConfirmButton: true,
        timer: 5000
      })
      this.isLoading = false
      return
    }

    const isNameValid = this.name.length >= 3

    if (!isNameValid) {
      Swal.fire({
        icon: 'error',
        text: 'You must enter your name',
        showConfirmButton: true,
        timer: 5000
      })
      this.isLoading = false
      return
    }

    // attempt login

    const loginData = {
      name: this.name,
      code: this.code
    }

    try {
      const { data } = await api.request.post('/login', loginData)

      window.localStorage.setItem('token', data.token)

      api.setToken()

      this.$router.replace({ name: 'dashboard' })

      this.isLoading = false
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'Invalid klassencode',
        timer: 5000
      })
      this.isLoading = false
    }
  }
}
